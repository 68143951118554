import { Title } from '@solidjs/meta';
import {
	Button,
	Container,
	Dialog,
	DialogContent,
	DialogTrigger,
	Heading,
	HorizontalRule,
	Link,
	Page,
	Section,
	TextLink,
} from '@troon/ui';
import { createEffect, createSignal, Show } from 'solid-js';
import { Icon } from '@troon/icons';
import { useNavigate } from '@solidjs/router';
import { FrequentlyAskedQuestions } from '../../../components/faqs';
import { Grid, GridHalf } from '../../../components/layouts/grid';
import { Hero } from '../../../components/hero/photo';
import { getConfigValue } from '../../../modules/config';
import { useUser } from '../../../providers/user';
import { AuthFlow } from '../../../partials/auth/auth';
import { UpsellAccessSection } from '../../../components/upsells/access';

export default function OldTroonCardLanding() {
	const user = useUser();
	const navigate = useNavigate();
	const [shouldRedirect] = createSignal(!user());

	createEffect(() => {
		if (shouldRedirect() && user()?.me.card?.id) {
			navigate(`/card/${user()?.me.card?.id}`);
		}
	});
	return (
		<>
			<Title>Troon Card | Troon</Title>

			<Hero src={`${getConfigValue('IMAGE_HOST')}/digital/hero/troon-card.jpeg`}>
				<Heading as="h1">2024 Troon Card</Heading>
				<Show when={user()?.me.card}>
					{(card) => (
						<Button as={Link} href={`/card/${card().id}`} class="size-fit shrink grow-0">
							Book with Troon Card
						</Button>
					)}
				</Show>
			</Hero>

			<Container>
				<div class="relative -top-24 z-30 -mb-12 rounded border border-red-500 bg-red-100 p-4 shadow-lg md:p-6">
					<p class="text-xl font-semibold text-red-600">
						<Icon name="circle-warning" /> All Troon Cards expire on Dec 31st.{' '}
						<TextLink href="/access" class="text-red-600 underline">
							Subscribe to Troon Access
						</TextLink>{' '}
						to continue getting discounted tee times at your favorite Troon courses
					</p>
				</div>
			</Container>

			<Container>
				<Page>
					<Show when={!user()}>
						<div class="z-30 -mt-32 flex flex-row flex-wrap items-center justify-between gap-4 rounded bg-white p-4 shadow-lg md:-mt-36 xl:flex-nowrap">
							<div class="flex flex-row items-center gap-4">
								<Icon name="user-circle" class="shrink-0 self-start text-3xl text-brand lg:self-center" />
								<p class="shrink grow">
									Log in to your Troon Rewards account to book tee times using your Troon Card benefits.
								</p>
							</div>
							<Dialog key="auth">
								<DialogTrigger as={Link} href="/auth" class="grow lg:shrink lg:grow-0">
									Log in
								</DialogTrigger>
								<DialogContent autoWidth noPadding noClose floatingClose>
									<AuthFlow />
								</DialogContent>
							</Dialog>
						</div>
					</Show>

					<UpsellAccessSection location="2024 Troon Card">
						<Heading as="h2">Better benefits, more access.</Heading>
						<p>
							The Troon Card has evolved into Troon Access! Enjoy exclusive benefits at over 150+ Troon courses
							including Kapalua, Troon North, and Gamble Sands.
						</p>

						<div class="flex flex-wrap justify-start gap-4">
							<Button class="sm:w-fit sm:shrink sm:grow-0" as={Link} href="/access">
								Learn more<span class="sr-only"> about Troon Access</span>
							</Button>
							<Button
								class="sm:w-fit sm:shrink sm:grow-0"
								appearance="transparent-current"
								as={Link}
								href="/access/troon-card-vs-troon-access"
							>
								<Icon name="info" /> Troon Card vs. Troon Access
							</Button>
						</div>
					</UpsellAccessSection>

					<HorizontalRule />

					<Section id="two-for-one">
						<Heading as="h2" class="mx-auto max-w-lg text-center">
							Troon Card “2 for 1’s”
						</Heading>
						<p class="mx-auto max-w-lg text-center">
							The Troon Card 2-for-1 is a benefit allowing a Troon Card holder and one guest to play a round of golf for
							the price of one Troon Card rate at specific courses.
						</p>
						<Container size="small">
							<div class="overflow-hidden rounded border border-neutral">
								<FrequentlyAskedQuestions id="2024-troon-card-terms" />
							</div>
						</Container>

						<Grid>
							<GridHalf class="overflow-hidden rounded-md border border-neutral">
								<Heading as="h3" size="p" class="bg-brand-700 px-4 py-2 font-semibold text-white">
									All Participating Courses in Arizona
								</Heading>
								<div class="flex flex-col gap-4 p-4">
									<p class="font-semibold">September 1, 2023 – June 2, 2024</p>
									<ul>
										<li>1 day in advance</li>
										<li>Monday – Sunday: After 11am</li>
										<li>*Blackout Dates for 2 for 1 use: Thursday, November 23, 2023 – Sunday, November 26, 2023 </li>
										<li>*Blackout Dates for 2 for 1 use: Monday, February 5, 2024 – Sunday, February 11, 2024</li>
									</ul>

									<p class="font-semibold">June 3, 2024 – August 29, 2024</p>
									<ul>
										<li>2 days in advance</li>
										<li>Monday – Sunday: After 9am</li>
									</ul>

									<p class="font-semibold">August 30, 2024 – December 31, 2024</p>
									<ul>
										<li>1 day in advance</li>
										<li>Monday – Sunday: After 11am</li>
										<li>*Blackout Dates for 2 for 1 use: Thursday, November 28, 2024 – Sunday, December 1, 2024</li>
									</ul>
								</div>
							</GridHalf>

							<GridHalf class="overflow-hidden rounded-md border border-neutral">
								<Heading as="h3" size="p" class="bg-brand-700 px-4 py-2 font-semibold text-white">
									All Participating Courses in California
								</Heading>
								<div class="flex flex-col gap-4 p-4">
									<p class="font-semibold">September 1, 2023 – June 2, 2024</p>
									<ul>
										<li>1 day in advance</li>
										<li>Monday – Sunday: After 11am</li>
									</ul>

									<p class="font-semibold">June 3, 2024 – August 29, 2024</p>

									<ul>
										<li>2 days in advance</li>
										<li>Monday – Sunday: After 11am</li>
									</ul>

									<p class="font-semibold">August 30, 2024 – December 31, 2024</p>

									<ul>
										<li>1 day in advance</li>
										<li>Monday – Sunday: After 11am</li>
									</ul>
								</div>
							</GridHalf>

							<GridHalf class="overflow-hidden rounded-md border border-neutral">
								<Heading as="h3" size="p" class="bg-brand-700 px-4 py-2 font-semibold text-white">
									All Participating Courses Outside Arizona, California, and Hawaii
								</Heading>
								<div class="flex flex-col gap-4 p-4">
									<p class="font-semibold">September 1, 2023 – December 31, 2024</p>
									<ul>
										<li>2 days in advance</li>
										<li>Monday – Sunday: After 11am</li>
									</ul>
								</div>
							</GridHalf>

							<GridHalf class="overflow-hidden rounded-md border border-neutral">
								<Heading as="h3" size="p" class="bg-brand-700 px-4 py-2 font-semibold text-white">
									All Participating Courses in Hawaii
								</Heading>
								<div class="flex flex-col gap-4 p-4">
									<p class="font-semibold">September 1, 2023 – December 31, 2024</p>
									<ul>
										<li>1 day in advance</li>
										<li>Monday – Sunday: After 11am</li>
									</ul>
								</div>
							</GridHalf>
						</Grid>
					</Section>

					<HorizontalRule />

					<Grid>
						<GridHalf>
							<Section>
								<Heading as="h2" size="h2">
									Frequently asked questions
								</Heading>

								<p>You have questions, we have answers. Not seeing what you’re looking for?</p>

								<Button as={Link} href="/about/contact" appearance="secondary" class="size-fit">
									Contact support
								</Button>
							</Section>
						</GridHalf>

						<GridHalf border>
							<FrequentlyAskedQuestions id="2024-troon-card-faqs" />
						</GridHalf>
					</Grid>
				</Page>
			</Container>
		</>
	);
}

export const route = { info: { hasHero: true } };
